import React from "react"
import { graphql } from "gatsby"
import { StructuredText } from "react-datocms"
import {
  isHeading,
  isParagraph,
  renderRule,
} from "datocms-structured-text-utils"
import { Box, Text, Container } from "@theme-ui/components"
import Layout from "../components/layout"
import { getArticlePath } from "../utils/path"
import { GatsbyImage } from "gatsby-plugin-image"
import ImageGallery from "../components/blocks/imageGallery"
import ArticleTitle from "../components/articleTitle"
import { HelmetDatoCms } from "gatsby-source-datocms"
import FilterMetaTagDescription from "../utils/filterMetaTags"

const Article = ({ data: { page } }) => {
  const i18nPaths = page._allSlugLocales.map(path => {
    return {
      locale: path.locale,
      value: getArticlePath(page, path.locale),
    }
  })

  return (
    <Layout locale={page.locale} i18nPaths={i18nPaths}>
      <HelmetDatoCms seo={FilterMetaTagDescription(page.seoMetaTags)}>
        <html lang={page.locale} />
      </HelmetDatoCms>
      <Box
        sx={{
          ".gatsby-image-wrapper": {
            height: "100%",
            // width: "100%",
            display: "block",
          },
          borderBottom: "2px solid",
          borderColor: "primary",
          position: "relative",
          minHeight: "14rem",
          maxHeight: "40rem",
          mt: ["6rem", "6rem", 0, 0],
          overflow: "hidden",
          backgroundColor: "dark",
        }}
      >
        {page.heroImage && <GatsbyImage image={page.heroImage.article} />}
        <Box
          sx={{
            position: "absolute",
            height: "100%",
            width: "100%",
            top: 0,
            left: 0,
            backgroundColor: "dark",
            opacity: 0.3,
          }}
        ></Box>
      </Box>
      <ArticleTitle page={page} />
      <Container variant="sm" sx={{ mb: [5, 7, 8] }}>
        <StructuredText
          data={page.body}
          customRules={[
            renderRule(
              isHeading,
              ({ adapter: { renderNode }, node, children, key }) => {
                return renderNode(
                  () => {
                    return (
                      <Text
                        as={`h${node.level}`}
                        variant={`h${node.level}`}
                        sx={{ mb: 3 }}
                      >
                        {children}
                      </Text>
                    )
                  },
                  { key },
                  children
                )
              }
            ),
            renderRule(
              isParagraph,
              ({ adapter: { renderNode }, node, children, key }) => {
                return renderNode(
                  () => {
                    return (
                      <Text as="p" mb={3} variant="article">
                        {children}
                      </Text>
                    )
                  },
                  { key },
                  children
                )
              }
            ),
          ]}
          renderBlock={({ record }) => {
            switch (record.__typename) {
              case "DatoCmsImageGallery":
                return (
                  <Box mt={5} mb={5}>
                    <ImageGallery
                      images={record.images}
                      fullWidth={record.fullWidth}
                      id={record.id}
                    />
                  </Box>
                )
              default:
                return null
            }
          }}
        />
      </Container>
    </Layout>
  )
}

export default Article

export const query = graphql`
  query ArticleQuery($id: String!, $locale: String!) {
    page: datoCmsArticle(id: { eq: $id }) {
      ...ArticleDetails
      ...ArticleAllSlugLocales
      ...ArticleMeta
      meta {
        firstPublishedAt(locale: $locale, formatString: "DD MMMM Y")
      }
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
    }
  }

  fragment ArticleMeta on DatoCmsArticle {
    meta {
      firstPublishedAt(locale: $locale, formatString: "DD MMMM Y")
    }
  }

  fragment ArticleAllSlugLocales on DatoCmsArticle {
    _allSlugLocales {
      value
      locale
    }
  }

  fragment ArticleDetails on DatoCmsArticle {
    id
    locale
    title
    slug
    model {
      apiKey
    }
    heroImage {
      article: gatsbyImageData(
        width: 1440
        height: 900
        placeholder: BLURRED
        forceBlurhash: false
      )
      list: gatsbyImageData(
        width: 600
        height: 300
        placeholder: BLURRED
        forceBlurhash: false
      )
    }
    category {
      title
      slug
      ...ArticleCategoryAllSlugLocales
    }
    ...ArticleBody
  }

  fragment ArticleBody on DatoCmsArticle {
    body {
      value
      blocks {
        __typename
        ... on DatoCmsImageGallery {
          id: originalId
          ...ImageGallery
        }
      }
    }
  }
`
